// i18n
import { i18n } from '@ecg-marktplaats/js-react-i18n';
import dateLocale from 'date-fns/locale/nl';
import translation from '~/locales/nl-BE/translation';
import common from '~/locales/nl-BE/common';
import { cache } from 'react';

const instance = i18n.init({
  lng: 'nl-BE',
  translations: {
    translation,
    common,
  },
  dateLocale,
});

export default {
  ...instance,
  t: cache(instance.t),
};
